<template>
  <footer>
    <div class="w-full">
      <!-- BANNER FOOTER -->
      <div
        class="banner-footer relative"
        :class="!aboutPage ? 'bg-banner-footer' : ''"
      >
        <div
          class="md:container md:w-[92%] mx-auto relative flex flex-col md:flex-row"
        >
          <div
            v-if="!showFooterBanner"
            class="general-banner"
            :class="aboutPage ? 'w-full md:w-1/2' : 'w-full md:before:hidden'"
          >
            <div
              class="flex justify-center md:justify-between gap-5 relative z-[2] py-8 md:py-15 w-[92%] mx-auto md:w-full md:mx-0"
              :class="aboutPage ? 'flex-col ' : 'flex-col md:flex-row'"
            >
              <template v-if="isLoading">
                <UtilsSkeletonLoading class="w-3/4 h-20" />
                <UtilsSkeletonLoading class="w-40 h-10 my-auto" />
              </template>
              <template v-else>
                <h2
                  class="text-white font-medium text-[22px] md:text-[40px] text-center md:text-left w-10/12 md:w-9/12 !leading-tight mx-auto md:mx-0"
                >
                  {{ footerText }}
                </h2>
                <NuxtLink
                  :to="footerUrl"
                  :title="footerButtonText"
                  class="btn btn-primary btn-md w-max h-fit my-auto"
                  :class="aboutPage ? 'mx-auto md:mx-0' : 'mx-auto'"
                  >{{ footerButtonText }}</NuxtLink
                >
              </template>
            </div>
          </div>
          <div v-if="aboutPage" class="w-full md:w-1/2 about-banner">
            <div
              class="md:pl-10 flex justify-center md:justify-between flex-col gap-5 items-center md:items-start relative z-[2] py-8 md:py-15 w-[92%] mx-auto md:w-full md:mx-0"
            >
              <template v-if="isLoading">
                <UtilsSkeletonLoading class="w-3/4 h-20" />
                <UtilsSkeletonLoading class="w-40 h-10 my-auto" />
              </template>
              <template v-else>
                <h2
                  class="text-white font-medium text-[22px] md:text-[40px] text-center md:text-left w-10/12 md:w-full !leading-tight"
                >
                  {{ joinUsFooter.attributes?.contact_us?.wording }}
                </h2>
                <NuxtLink
                  :to="joinUsFooter.attributes?.contact_us?.cta_url"
                  :title="joinUsFooter.attributes?.contact_us?.cta_wording"
                  class="btn btn-primary btn-md w-max md:mt-0 mx-auto md:mx-0"
                  >{{
                    joinUsFooter.attributes?.contact_us?.cta_wording
                  }}</NuxtLink
                >
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- BANNER FOOTER -->

      <!-- TOP FOOTER -->
      <div class="bg-white py-5 md:py-9 border-t border-[#D2D2D2]">
        <div class="container grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-7">
          <div class="md:max-w-[428px]">
            <p
              class="text-xl md:text-2xl leading-[26px] md:leading-8 text-bw-900 font-semibold mb-2 md:mb-4"
            >
              Suitmedia
            </p>
            <p class="text-sm md:base-text text-bw-800">
              Your trusted partner for digital transformation in Indonesia. We
              are a full-service agency specializing in technology and marketing
              solutions, including web design, mobile app development, and
              digital marketing.
            </p>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-7">
            <div class="information">
              <p
                class="base-text md:text-lg leading-[26px] text-bw-900 font-semibold mb-2 md:mb-4"
              >
                Information
              </p>
              <ul class="grid grid-cols-2 gap-2 md:gap-y-2.5 md:gap-x-5">
                <li v-for="info in informations" :key="info.url">
                  <NuxtLink
                    :to="info.url"
                    :title="info.title"
                    class="text-sm md:base-text text-bw-900 hover:text-primary-primary"
                  >
                    {{ info.title }}
                  </NuxtLink>
                </li>
              </ul>
            </div>

            <div class="socmed">
              <p
                class="base-text md:text-lg leading-[26px] text-bw-900 font-semibold mb-2 md:mb-4"
              >
                Engage with Us
              </p>
              <FooterSocialMedia />
            </div>
          </div>
        </div>
      </div>

      <div class="w-full border-t border-[#D2D2D2]">
        <div
          class="container mx-auto flex flex-col md:flex-row justify-center py-6 gap-4 md:gap-0"
        >
          <div
            class="flex flex-col md:flex-row flex-wrap md:justify-end lg:justify-normal gap-x-4 gap-y-4 md:gap-y-0 items-center mx-auto md:mx-0 text-sm md:text-base"
          >
            <p class="text-bw-700 font-normal text-center">
              &copy; Suitmedia 2009-{{ currentYear }}. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
const route = useRoute()

const aboutPage = computed(() => {
  return route.path === '/about'
})
const showFooterBanner = computed(() => {
  return (
    route.path === '/careers' ||
    route.name === 'sustainability-slug' ||
    route.name === 'careers-slug' ||
    route.name === 'ideas-slug' ||
    route.name === 'videos-slug' ||
    route.name === 'contact' ||
    route.name === 'static'
  )
})

const isLoading = ref(true)

const footers = ref([])
const contactUsFooter = ref({})
const joinUsFooter = ref({})
const careerEvolutionFooter = ref({})
const currentYear = new Date().getFullYear()

const informations = ref([
  {
    title: 'About Us',
    url: '/about'
  },
  {
    title: 'Services',
    url: '/expertises'
  },
  {
    title: 'Portfolio',
    url: '/work'
  },
  {
    title: 'Career',
    url: '/careers'
  },
  {
    title: 'Contact',
    url: '/contact'
  },
  {
    title: 'Privacy Policy',
    url: '/privacy-policy'
  }
])

const getFooters = async () => {
  const { data, error, pending } = await useSuitmediaApiData(`/api/footers`, {
    query: {
      'fields[0]': 'id',
      'fields[1]': 'type',
      populate: 'contact_us'
    }
  })
  isLoading.value = pending.value

  if (error.value) {
    throw createError({
      statusCode: 404,
      message: `Case Studies does not exist`
    })
  }

  return data.value
}

const footersRes = await getFooters()
footers.value = footersRes.data
contactUsFooter.value = footers.value.find(
  item => item.attributes.type === 'contact-us'
)
joinUsFooter.value = footers.value.find(
  item => item.attributes.type === 'join-us'
)
careerEvolutionFooter.value = footers.value.find(
  item => item.attributes.type === 'career-evolution'
)

// COMPUTED FOOTER BANNER
const footerText = computed(() => {
  if (
    route.path.includes('/about/') ||
    route.path.includes('/sustainability')
  ) {
    return joinUsFooter.value.attributes?.contact_us?.wording
  } else if (route.path.includes('/culture')) {
    return careerEvolutionFooter.value.attributes?.contact_us?.wording
  }
  return contactUsFooter.value.attributes?.contact_us?.wording
})

const footerButtonText = computed(() => {
  if (
    route.path.includes('/about/') ||
    route.path.includes('/sustainability')
  ) {
    return joinUsFooter.value.attributes?.contact_us?.cta_wording
  } else if (route.path.includes('/culture')) {
    return careerEvolutionFooter.value.attributes?.contact_us?.cta_wording
  }
  return contactUsFooter.value.attributes?.contact_us?.cta_wording
})
const footerUrl = computed(() => {
  if (
    route.path.includes('/about/') ||
    route.path.includes('/sustainability')
  ) {
    return joinUsFooter.value.attributes?.contact_us?.cta_url
  } else if (route.path.includes('/culture')) {
    return careerEvolutionFooter.value.attributes?.contact_us?.cta_url
  }
  return contactUsFooter.value.attributes?.contact_us?.cta_url
})
// COMPUTED FOOTER BANNER
</script>

<style lang="scss" scoped>
.bg-banner-footer {
  background: linear-gradient(324.36deg, #111620 28.59%, #595959 163.06%),
    linear-gradient(0deg, #bebebe, #bebebe);
}

footer {
  overflow: hidden;
}

.general-banner {
  position: relative;
  background: linear-gradient(324.36deg, #111620 28.59%, #595959 163.06%),
    linear-gradient(0deg, #bebebe, #bebebe);

  @media screen and (min-width: 768px) {
    background: none;
    &::before {
      content: '';
      background: linear-gradient(324.36deg, #111620 28.59%, #595959 163.06%),
        linear-gradient(0deg, #bebebe, #bebebe);
      height: 100%;
      position: absolute;
      left: -100%;
      right: 0;
    }
  }
}
.about-banner {
  position: relative;
  background: linear-gradient(324.36deg, #111620 28.59%, #595959 163.06%),
    linear-gradient(0deg, #bebebe, #bebebe);

  @media screen and (min-width: 768px) {
    background: none;
    &::before {
      content: '';
      background: linear-gradient(324.36deg, #111620 28.59%, #595959 163.06%),
        linear-gradient(0deg, #bebebe, #bebebe);
      height: 100%;
      position: absolute;
      right: -100%;
      left: 0;
    }
  }
}

:deep(.container) {
  .nuxt-icon svg {
    margin-bottom: 0;
  }
}

:deep(.icons) {
  .nuxt-icon svg {
    width: 28px;
    height: 28px;

    transition: all 0.2s ease-in-out;

    @media screen and (min-width: 768px) {
      width: 32px;
      height: 32px;
    }

    &:hover {
      transform: scale(1.25);
    }
  }
}

:deep(.icon-send) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
}
</style>
